<template>
  <div class="data-list-container">
    <vx-card title="Users">
      <rj-users-list
        class="table-fixed"
        :users="users"
        @selected="toggleUserModal"
      >
        <template #actions>
          <vs-button
            class="w-full"
            @click="add"
          >
            Add User
          </vs-button>
        </template>

        <template #table-head>
          <vs-th
            sort-key="name"
            class="w-4/12"
          >
            Name
          </vs-th>
          <vs-th
            sort-key="role"
            class="w-3/12"
          >
            Role
          </vs-th>
          <vs-th
            sort-key="email"
            class="w-4/12"
          >
            Email
          </vs-th>
          <vs-th class="w-1/12">
            Action
          </vs-th>
        </template>

        <template #table-row="{ user }">
          <!-- NAME -->
          <vs-td class="whitespace-no-wrap">
            <p class="font-medium truncate">
              {{ user.firstName }} {{ user.lastName }}
            </p>
          </vs-td>

          <!-- ROLE -->
          <vs-td class="w-3/12 whitespace-no-wrap max-w-1">
            <p class="font-medium truncate">
              {{ user.role.description }}
            </p>
          </vs-td>

          <!-- Email -->
          <vs-td class="w-4/12 whitespace-no-wrap max-w-1">
            <p class="font-medium truncate">
              {{ user.email }}
            </p>
          </vs-td>

          <!-- ACTION -->
          <vs-td class="w-1/12 whitespace-no-wrap">
            <vs-button
              icon-pack="feather"
              icon="icon-settings"
              type="flat"
              class="inline-block"
            />
          </vs-td>
        </template>
      </rj-users-list>
    </vx-card>

    <rj-user-modal
      v-if="modal"
      context="partner"
      :user="selected"
      :active.sync="modal"
      :partner-id="partner.id"
      @create-user="create"
      @update-user="update"
    />
  </div>
</template>

<script>
import RjUsersList from '@/views/common/users/RjUsersList.vue';
import RjUserModal from '@/views/common/users/modals/RjUserModal.vue';

export default {
  name: 'RjPartnerUsersTable',

  components: {
    RjUserModal,
    RjUsersList,
  },

  data() {
    return {
      modal: false,
      selected: null,
    };
  },

  computed: {
    users() {
      return this.$store.getters['users/users'];
    },

    partner() {
      return this.$store.getters['partners/partner'];
    },
  },

  methods: {
    /**
     * Defers to the ToggleUserModal method with an empty user object
     *
     * @return {void}
     */
    add() {
      this.toggleUserModal();
    },

    async create(form) {
      try {
        const { locationAccessControl, ...payload } = form;
        await this.$store.dispatch('users/createUser', payload);

        this.$vs.notify({ title: 'Success', text: 'User created.', color: 'success' });
        this.modal = false;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async update(form) {
      try {
        const { locationAccessControl, ...payload } = form;
        await this.$store.dispatch('users/updateUser', { userId: form.id, payload });

        this.$vs.notify({ title: 'Success', text: 'User updated.', color: 'success' });
        this.modal = false;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    /**
     * Toggles the user modal visibility.
     *
     * @param  {Object} user
     * @return {void}
     */
    toggleUserModal(user = null) {
      this.modal = !this.modal;
      this.selected = user;
    },
  },
};
</script>
