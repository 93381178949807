<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <template v-if="loading">
      <div class="animate-pulse flex flex-col items-center">
        <div class="w-1/2 h-6 rounded-full bg-gray-400" />
        <div class="w-full h-3 rounded-full bg-gray-300 mt-5" />
        <div class="w-10/12 h-3 rounded-full bg-gray-300 mt-2" />
        <div class="w-10/12 h-48 rounded-lg bg-gray-300 my-6" />

        <div class="w-64 h-16 rounded-lg bg-gray-300" />
      </div>
    </template>

    <template v-else>
      <div class="mb-6 text-center">
        <h1 class="text-3xl">
          Congrats! You added the location.
        </h1>
        <p class="text-lg mt-2 text-gray-600">
          You will be charged a prorated amount of {{ proratedAmount }} now and $99.00 USD monthly for this
          location beginning on the next {{ renewalDay }} of the month.
        </p>

        <div class="my-6">
          <img
            src="https://media.giphy.com/media/HTjcWZwMtHpyhuCGKZ/giphy.gif"
            class="block mx-auto w-1/2"
          >
        </div>

        <p class="text-lg text-gray-600">
          Would you like to add another location?
        </p>
      </div>

      <div class="flex items-center justify-center mt-6 space-x-6">
        <vs-button
          size="large"
          type="border"
          :disabled="submitting"
          @click="$emit('finish')"
        >
          {{ !submitting ? 'I\'m Done' : 'Finalizing...' }}
        </vs-button>

        <vs-button
          v-if="!submitting"
          size="large"
          @click="$emit('add-another')"
        >
          Add Another
        </vs-button>
      </div>
    </template>
  </section>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'RjConfirmationScreen',

  props: {
    submitting: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      proratedAmount: '',
      renewalDay: '',
      loading: true,
    };
  },

  async mounted() {
    const { amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');
    this.proratedAmount = this.formatCentsAsDollars(amount);
    this.renewalDay = format(renewalDate, 'do');
    this.loading = false;
  },
};
</script>
