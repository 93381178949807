<template>
  <section>
    <template v-if="loading">
      <p>Loading Locations...</p>
    </template>

    <template v-else>
      <rj-locations-list
        :locations="locations"
        @selected="jumpToLocation"
      >
        <template #actions>
          <vs-button
            class="w-full"
            @click="add"
          >
            Add Location
          </vs-button>
        </template>

        <template #table-row="{ location }">
          <!-- Name -->
          <vs-td class="whitespace-no-wrap">
            <p class="font-medium truncate">
              {{ location.name }}
            </p>
          </vs-td>

          <!-- Address -->
          <vs-td class="whitespace-no-wrap">
            <p class="font-medium truncate">
              {{ location.addressStreet }}
            </p>
          </vs-td>

          <!-- Reputation Grade -->
          <vs-td class="whitespace-no-wrap w-full ml-auto mr-auto">
            <p class="w-full text-center">
              {{ location.reputationGrade[location.reputationGrade.length - 1].rpg }}%
            </p>
          </vs-td>

          <!-- Competitor Review Score -->
          <vs-td class="whitespace-no-wrap">
            <p class="w-full text-center">
              {{ location.competitorReviewScore }}
            </p>
          </vs-td>

          <!-- Satisfaction Score -->
          <vs-td class="whitespace-no-wrap">
            <p class="w-full text-center">
              {{ location.satisfactionScore }}%
            </p>
          </vs-td>

          <!-- Thirty Day Count -->
          <vs-td class="whitespace-no-wrap">
            <p class="font-medium w-full text-center">
              {{ location.thirtyDayCount[location.thirtyDayCount.length - 1].value }}
            </p>
          </vs-td>

          <!-- Actions -->
          <vs-td class="whitespace-no-wrap">
            <span class="w-full flex justify-center">
              <vs-button
                icon-pack="feather"
                icon="icon-settings"
                type="flat"
                class="inline-block"
              />
              <vs-button
                icon-pack="feather"
                icon="icon-slash"
                type="flat"
                class="inline-block"
                @click.stop="removeConfirmation(location)"
              />
            </span>
          </vs-td>
        </template>
      </rj-locations-list>
    </template>

    <rj-add-location-wizard
      v-if="wizard"
      :plan="plan.code"
      :company="company"
      @close="wizard = false"
    />
  </section>
</template>

<script>
import useLocationsTab from '@/views/common/locations/useLocationsTab';
import RjLocationsList from '@/views/common/locations/RjLocationsList.vue';
import RjAddLocationWizard from '@/views/common/locations/modals/RjAddLocationWizard.vue';

export default {
  name: 'RjLocationsTab',

  components: {
    RjLocationsList,
    RjAddLocationWizard,
  },

  mixins: [useLocationsTab],

  props: {
    plan: {
      type: Object,
      required: true,
    },

    company: {
      type: Object,
      required: true,
    },
  },

  computed: {
    locations() {
      return this.$store.getters['locations/locations'];
    },
  },

  methods: {
    /**
     * Navigate to the given location page
     *
     * @param  {Object} location
     * @return {void}
     */
    jumpToLocation(location) {
      this.$router.push({
        name: 'partner-locations-settings',
        params: { location: location.id },
      });
    },
  },
};
</script>
