<template>
  <vx-card title="Partner Details">
    <form action="">
      <!-- Company Name -->
      <vs-input
        v-model="form.name"
        label="Company Name"
        class="w-full"
      />

      <!-- Main Contact -->
      <div class="mt-3">
        <label class="vs-input--label">Main Contact</label>
        <rj-select
          v-model="form.mainContactId"
          :options="users"
          :reduce="(user) => user.id"
          class="w-full"
        />
      </div>

      <!-- Phone Number -->
      <vs-input
        v-model="form.phone"
        label="Phone Number"
        class="w-full mt-3"
      />

      <div class="vx-row">
        <!-- Country -->
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <label class="vs-input--label">Country</label>
          <rj-select
            v-model="form.addressCountry"
            :options="countries"
            :reduce="(country) => country.code"
            :clearable="false"
            class="w-full"
          />
        </div>

        <!-- State -->
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <label class="vs-input--label">State/Province/Region</label>
          <rj-select
            v-model="form.addressRegion"
            :options="regions"
            :reduce="(region) => region.code"
            :clearable="false"
            class="w-full"
          />
        </div>
      </div>

      <!-- Street Address -->
      <vs-input
        v-model="form.addressStreet"
        class="w-full mt-3"
        label="Street Address"
      />

      <div class="vx-row">
        <!-- City -->
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <vs-input
            v-model="form.addressCity"
            class="w-full"
            label="City"
          />
        </div>

        <!-- Zip Code -->
        <div class="vx-col sm:w-1/2 w-full mt-3">
          <vs-input
            v-model="form.addressZip"
            class="w-full"
            label="Zip/Postal Code"
          />
        </div>
      </div>

      <!-- Website -->
      <vs-input
        v-model="form.website"
        class="w-full mt-3"
        label="Website"
      />

      <div class="flex flex-wrap items-center justify-end mt-6">
        <vs-button
          v-if="!pristine"
          @click="submit"
        >
          Save Changes
        </vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
import _debounce from 'lodash/debounce';
import { isPristine } from '@/utils';

export default {
  name: 'RjPartnerDetailsForm',

  components: { },

  data() {
    const {
      addressCountry,
      addressRegion,
      addressStreet,
      addressCity,
      addressZip,
      mainContact,
      name,
      phone,
      website,
    } = this.$store.getters['partners/partner'];

    return {
      pristine: true,
      old: {},
      form: {
        name,
        mainContactId: mainContact?.id,
        phone,
        addressCountry,
        addressRegion,
        addressStreet,
        addressCity,
        addressZip,
        website,
      },

    };
  },

  computed: {
    countries() {
      return this.$store.getters['geography/countries'].map((c) => ({
        label: c.countryName,
        code: c.countryShortCode,
      }));
    },

    regions() {
      if (!this.form.addressCountry) {
        return [];
      }
      const country = this.$store.getters['geography/countries'].find(
        (c) => c.countryShortCode === this.form.addressCountry,
      );
      return country.regions.map((r) => ({
        label: r.name,
        code: r.shortCode,
      }));
    },

    users() {
      return this.$store.getters['users/users'].map((u) => ({
        label: `${u.firstName} ${u.lastName}`,
        id: u.id,
      }));
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
    old: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    this.old = { ...this.form };
  },

  methods: {
    async submit() {
      const payload = this.form;
      await this.$store.dispatch(
        'partners/updatePartnerDetails',
        payload,
      );
      const error = this.$store.getters['partners/error'];
      if (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      } else {
        this.$vs.notify({
          title: 'Success',
          text: 'Partner saved.',
          color: 'success',
        });
      }
      this.old = { ...this.form };
    },
    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(original) {
      this.pristine = isPristine(original, this.old);
    }, 150),
  },
};
</script>
