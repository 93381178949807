<template>
  <div data-main-root>
    <Main
      :vertical-nav-menu-items="verticalNavMenuItems"
      :horizontal-nav-menu-items="horizontalNavMenuItems"
    />

    <template v-if="psp_show">
      <component
        :is="psp_modal"
        :props="psp_props"
      />
    </template>
  </div>
</template>

<script>
import { bus, httpBuildQuery } from '@/utils';
import Main from '@/layouts/main/Main.vue';
import Partner from '@/http/middleware/Partner';
import Authorizable from '@/mixins/Authorizable';
import Auth from '@/http/middleware/Authenticate';
import { CERTIFIED_PARTNER_PLAN, HIGH_JUMP_CODE, LOW_JUMP_CODE } from '@/rj_consts';
import PartnerServiceProvider from '@/views/partner-dashboard/PartnerServiceProvider';

export default {
  name: 'RjPartnerDashboard',

  components: { Main },

  mixins: [PartnerServiceProvider, Authorizable],

  middleware: [Auth, Partner],

  computed: {
    horizontalNavMenuItems() {
      if (!this.hasValidPaymentMethod) {
        return [
          {
            url: null,
            icon: 'LogOutIcon',
            color: 'rjPurple',
            title: 'Logout',
            action: this.logout,
          },
        ];
      }
      return [
        {
          url: null,
          component: 'NotificationDropDown',
        },
        {
          url: null,
          icon: 'UserPlusIcon',
          color: 'rjPurple',
          title: 'Add Company',
          action: this.showCompanyWizard,
        },
        {
          url: null, // In-house account.
          icon: 'ExternalLinkIcon',
          color: 'rjPurple',
          title: 'Open In-House Account',
          action: this.jumpToInHouseAccount,
        },
        {
          url: null, // Partner Success Kit
          icon: 'ZapIcon',
          color: 'rjPurple',
          title: 'Partner Success Kit',
          action: this.openPartnerSuccessKit,
        },
        {
          url: null,
          icon: 'LogOutIcon',
          color: 'rjPurple',
          title: 'Logout',
          action: this.logout,
        },
      ];
    },

    verticalNavMenuItems() {
      if (!this.hasValidPaymentMethod) {
        return [
          {
            url: '/partner/accounting',
            name: 'Accounting',
            slug: 'partner-accounting',
            icon: 'DollarSignIcon',
          },
          {
            url: null,
            name: 'Help',
            slug: 'partner-help',
            icon: 'HelpCircleIcon',
            submenu: [
              {
                url: '/partner/support',
                name: 'Support',
                slug: 'partner-help-support',
                icon: 'FileTextIcon',
              },
            ],
          },
        ];
      }
      return [
        {
          url: '/partner',
          name: 'Home',
          slug: 'partner-home',
          icon: 'HomeIcon',
        },
        {
          url: null,
          name: 'Accounts',
          slug: 'partner-accounts',
          icon: 'UsersIcon',
          submenu: [
            {
              url: '/partner/companies',
              name: 'Companies',
              slug: 'partner-accounts-companies',
              icon: 'UserPlusIcon',
            },
            {
              url: null,
              name: 'Add Company',
              slug: null,
              icon: 'UserPlusIcon',
              action: this.showCompanyWizard,
            },
            {
              url: null,
              name: 'In-House Account',
              slug: 'partner-in-house-account',
              icon: 'LogInIcon',
              action: this.jumpToInHouseAccount,
            },
          ],
        },
        {
          url: '/partner/accounting',
          name: 'Accounting',
          slug: 'partner-accounting',
          icon: 'DollarSignIcon',
        },
        {
          url: '/partner/settings',
          name: 'Settings',
          slug: 'partner-settings',
          icon: 'SettingsIcon',
        },
        {
          url: null,
          name: 'Help',
          slug: 'partner-help',
          icon: 'HelpCircleIcon',
          submenu: [
            {
              url: null,
              name: 'Partner Success Kit',
              slug: 'partner-help-partner-success-kit',
              icon: 'ZapIcon',
              action: this.openPartnerSuccessKit,
            },
            {
              url: '/partner/support',
              name: 'Support',
              slug: 'partner-help-support',
              icon: 'FileTextIcon',
            },
          ],
        },
      ];
    },

    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    partner() {
      return this.$store.getters['partners/partner'];
    },

    hasValidPaymentMethod() {
      return this.$store.getters['partners/hasValidPaymentMethod'];
    },
  },

  watch: {
    hasValidPaymentMethod: {
      immediate: true,
      handler: 'checkPaymentMethod',
    },
  },

  created() {
    this.$store.dispatch('roles/getRoles');
    this.$store.dispatch('revenuejump/getCategories');
    this.$store.dispatch('revenuejump/getReviewSites');
    this.$store.dispatch('revenuejump/getPlanFeatures');

    this.$store.dispatch('companies/getCompanies', httpBuildQuery({
      filter: JSON.stringify({
        where: {
          partnerId: this.auth.user.partnerId,
          active: true,
        },
        include: [
          {
            relation: 'plan',
            scope: {
              where: {
                code: { inq: [HIGH_JUMP_CODE, LOW_JUMP_CODE] },
              },
            },
          },
        ],
      }),
    }));
  },

  methods: {
    jumpToInHouseAccount(event) {
      if (this.guard_partner_plan(CERTIFIED_PARTNER_PLAN)) {
        event.preventDefault();
        bus.$emit('elite-upgrade-cta');
      }

      const inHouseCompany = this.$store.getters['partners/inHouseCompany'];

      if (inHouseCompany) {
        localStorage.setItem('actAsCompanyId', inHouseCompany.id);
        const route = this.$router.resolve({
          name: 'company-home',
        });
        window.open(route.href, '_blank');
      }
    },

    async logout() {
      await this.$store.dispatch('auth/logout');

      return this.$router.replace({ name: 'login' });
    },

    showCompanyWizard(event) {
      event.preventDefault();
      bus.$emit('show-modal', { modal: 'add-company' });
    },

    openPartnerSuccessKit() {
      window.open('https://docs.google.com/document/d/1enUzG7TDG4encVreZapk1J3uwkQZNEkl1nYsfdIRPvk/edit?usp=sharing', '_blank');
    },

    checkPaymentMethod() {
      if (!this.hasValidPaymentMethod && this.$route.name !== 'partner-accounting') {
        bus.$emit('payment-method-alert');
        this.$router.replace({
          name: 'partner-accounting',
        });
      }
    },
  },
};
</script>
