<template>
  <section class="rj-invoice-modal">
    <header class="p-4 flex items-center justify-end no-print">
      <vs-button
        type="border"
        @click="download"
      >
        Download
      </vs-button>
      <a
        ref="downloadLink"
        class="hidden"
      />
      <vs-button
        class="ml-3"
        @click="print"
      >
        Print
      </vs-button>
    </header>

    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
    >
      <main
        v-if="refund"
        id="invoice-page"
        slot="pdf-content"
        ref="invoice"
        class="max-w-lg mx-auto"
      >
        <div id="invoice-container">
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-1/2 mt-base">
              <img
                src="/rj-logo.png"
                alt="RevenueJump Logo"
                class="w-48"
              >
            </div>

            <div class="vx-col w-1/2 text-right">
              <h1>Refund</h1>
              <div class="invoice__invoice-detail mt-6">
                <p>{{ refundDate }}</p>
              </div>
            </div>

            <div class="vx-col w-full mt-4 my-4">
              <div class="flex justify-between">
                <span />
                <p class="font-bold">
                  TO
                </p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.addressLine1 }}</p>
                <p>{{ refund.partner.addressName || refund.partner.name }}</p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.addressLine2 }} {{ companyDetails.zipcode }}</p>
                <p>{{ refund.partner.addressStreet }}</p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.mailId }}</p>
                <p>{{ refund.partner.addressCity }} {{ refund.partner.addressRegion }} {{ refund.partner.addressZip }}</p>
              </div>
            </div>

            <!-- INVOICE CONTENT -->
            <div class="mt-3 w-full">
              <table class="w-full">
                <thead class="border-b-2 border-gray-400">
                  <tr>
                    <th class="py-1 px-3 m-0 w-full">
                      REFUND
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      TOTAL
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="py-1 px-3">
                      <p>{{ refundNote }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ refundTotal }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="flex justify-between border-t-2 border-gray-400 py-2 mt-2">
                <div class="w-7/12 space-y-2 mr-4">
                  <div class="leading-tight">
                    <span class="font-semibold">Currency:</span> USD
                  </div>

                  <div class="leading-tight">
                    <p class="font-semibold">
                      Terms of Service:
                    </p>
                    <a
                      href="https://www.revenuejump.com/terms-of-service"
                      target="_blank"
                    >
                      https://www.revenuejump.com/terms-of-service
                    </a>
                  </div>
                </div>

                <div class="w-5/12 space-y-2 text-right">
                  <div class="leading-tight text-xl">
                    <p
                      class="uppercase text-sm font-bold"
                      :style="{ color: Color(themeColors.rjPurple).lightness(70).hex() }"
                    >
                      Total
                    </p>
                    <span class="text-2xl font-bold">{{ refundTotal }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </vue-html2pdf>
  </section>
</template>

<script>
import Color from 'color';
import { format } from 'date-fns';
import VueHtml2pdf from 'vue-html2pdf';
import { formatCentsAsDollars } from '@/utils';
import Partner from '@/http/middleware/Partner';
import Auth from '@/http/middleware/Authenticate';
import { colors as themeColors } from '@/../themeConfig';

export default {
  name: 'RjRefund',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueHtml2pdf,
  },
  middleware: [Auth, Partner],

  data() {
    return {
      Color,
      themeColors,
      active: true,
      refund: null,
      companyDetails: {
        name: 'RevenueJump',
        addressLine1: '6700 N Linder Rd #156-143',
        addressLine2: 'Meridian, ID',
        zipcode: '83646',
        mailId: 'support@revenuejump.com',
        mobile: '',
      },
    };
  },

  computed: {
    filename() {
      if (!this.refund) {
        return 'RevenueJump-Refund';
      }
      return `RevenueJump-Refund-${this.refund.created}`;
    },
    refundDate() {
      return format(new Date(this.refund.created * 1000), 'MM/dd/yyyy');
    },
    refundTotal() {
      return formatCentsAsDollars(this.refund.amount);
    },
    refundNote() {
      if (!this.refund) {
        return '';
      }
      return this.refund.metadata?.note || 'Manual Refund';
    },
  },

  async created() {
  //   document.body.classList.toggle('overflow-hidden');
  //   document.addEventListener('keydown', this.onEscape);
    const refundId = this.$route.params.refund;
    const partnerId = this.$route.params.partner;
    this.refund = await this.$store.dispatch('partners/getRefund', { partnerId, refundId });
  },

  methods: {
    /**
     * Listener for the 'Esc' key to close the modal
     *
     * @param  {Event} event
     * @return {void}
     */
    onEscape(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    },

    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      this.active = false;
      this.$emit('close');
    },

    print() {
      window.print();
    },

    async download() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style>
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.vue-html2pdf .layout-container {
  position: static !important;
  background-color: #fff !important;
  z-index: 0;
}
</style>
