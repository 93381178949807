<template>
  <section>
    <template v-if="loading">
      <p>Loading Funnel...</p>
    </template>

    <template v-else>
      <header class="flex flex-wrap  items-center justify-between mb-6">
        <div class="flex flex-wrap items-center">
          <h2
            class="pr-3 font-semibold text-gray-600 mr-3 border-0 md:border-r border-solid border-gray-500"
          >
            {{ company.name }}
          </h2>
          <rj-breadcrumb :items="breadcrumbs" />
        </div>

        <div
          v-if="valid"
          class="mt-3 sm:mt-0"
        >
          <rj-funnel-form-actions
            :pristine="pristine"
            @save-funnel="onSave"
            @reset-funnel="reset"
          />
        </div>
      </header>

      <rj-funnel-statistics v-if="mode === 'edit'" />

      <div class="flex flex-wrap -mx-4">
        <div class="w-full sm:w-4/12 px-4 mb-4">
          <rj-funnel-form
            ref="funnelForm"
            :mode="mode"
          />
        </div>

        <div class="w-full sm:w-8/12 px-4">
          <rj-funnel-contents ref="funnelContents" />
        </div>
      </div>

      <div
        v-if="valid"
        class="flex justify-end mt-6"
      >
        <rj-funnel-form-actions
          :pristine="pristine"
          @save-funnel="onSave"
          @reset-funnel="reset"
        />
      </div>
    </template>
  </section>
</template>

<script>
import useFunnelSettings from '@/views/common/funnels/useFunnelSettings';
import RjFunnelForm from '@/views/common/funnels/partials/RjFunnelForm.vue';
import RjFunnelContents from '@/views/common/funnels/partials/RjFunnelContents.vue';
import RjFunnelStatistics from '@/views/common/funnels/partials/RjFunnelStatistics.vue';
import RjFunnelFormActions from '@/views/common/funnels/partials/RjFunnelFormActions.vue';

export default {
  name: 'RjFunnelSettings',

  components: {
    RjFunnelForm,
    RjFunnelContents,
    RjFunnelStatistics,
    RjFunnelFormActions,
  },

  mixins: [useFunnelSettings],

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  computed: {
    breadcrumbs() {
      const { id, name } = this.company;

      return [
        { title: 'Companies', url: '/partner/companies' },
        { title: name, url: `/partner/companies/${id}/general` },
        { title: 'Funnels', url: `/partner/companies/${id}/funnels` },
        { title: this.funnel.name, active: true },
      ];
    },
  },

  methods: {
    /**
     * Redirects to the given funnel
     *
     * @param  {Object} funnel
     * @return {void}
     */
    redirectTo(funnel) {
      this.$router.push({
        name: 'partner-funnel-settings',
        params: { funnel: funnel.id },
      });
    },

    reset() {
      this.$refs.funnelContents.reset();
      this.$refs.funnelForm.reset();
    },
  },
};
</script>
