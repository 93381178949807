<template>
  <section>
    <template v-if="loading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <div class="flex content-center flex-wrap -mx-4 mb-2">
        <!-- Renewal  -->
        <rj-renewal-date
          :upcoming-invoice="upcomingInvoice"
        />

        <!-- Commission  -->
        <rj-commission
          v-if="upcomingInvoice"
          :num-high-jumps="numHighJumps"
        />

        <!-- Elite Partner Bonus  -->
        <rj-elite-partner-bonus v-if="upcomingInvoice" />

        <!-- Payment Method  -->
        <rj-payment-method
          :payment-method="paymentMethod"
          @payment-method-updated="setPaymentMethod"
        />
      </div>
      <div
        class="flex flex-wrap -mx-4"
      >
        <!-- Upcoming Invoice -->
        <rj-upcoming-invoice
          v-if="upcomingInvoice"
          :upcoming-invoice="upcomingInvoice"
        />

        <!-- Transaction History -->
        <rj-transactions-list :transactions="transactions" />
      </div>
    </template>
  </section>
</template>

<script>
import Color from 'color';
import { format } from 'date-fns';
import { formatCentsAsDollars, httpBuildQuery } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import { HIGH_JUMP_CODE } from '@/rj_consts';
import { colors as themeColors } from '@/../themeConfig';
import RjRenewalDate from '@/views/common/partners/partials/RjRenewalDate.vue';
import RjCommission from '@/views/common/partners/partials/RjCommission.vue';
import RjElitePartnerBonus from '@/views/common/partners/partials/RjElitePartnerBonus.vue';
import RjPaymentMethod from '@/views/common/partners/partials/RjPaymentMethod.vue';
import RjUpcomingInvoice from '@/views/common/partners/partials/RjUpcomingInvoice.vue';
import RjTransactionsList from '@/views/common/partners/partials/RjTransactionsList.vue';


export default {
  name: 'RjAccounting',

  components: {
    RjRenewalDate,
    RjCommission,
    RjElitePartnerBonus,
    RjPaymentMethod,
    RjUpcomingInvoice,
    RjTransactionsList,
  },

  mixins: [Authorizable],

  data() {
    return {
      commissionStatements: [],
      invoices: [],
      loading: true,
      refunds: [],
      upcomingInvoice: null,
      paymentMethod: null,
      themeColors,
      Color,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },

    numHighJumps() {
      const highJumpLineItem = this.upcomingInvoice?.lineItems.find((item) => item.code === HIGH_JUMP_CODE);
      if (!highJumpLineItem) {
        return 0;
      }
      return highJumpLineItem.quantity;
    },

    transactions() {
      const transactions = [...this.invoices, ...this.refunds];
      transactions.sort((a, b) => b.created - a.created);
      for (const stmt of this.commissionStatements) {
        const index = transactions.findIndex((invoice) => invoice.number === stmt.number);
        if (index > -1) {
          transactions.splice(index, 0, stmt);
        }
      }
      return transactions;
    },
  },

  async mounted() {
    const filters = httpBuildQuery({
      filter: JSON.stringify({
        include: [
          'partnerPlan',
          'mainContact',
          {
            relation: 'users',
            scope: {
              include: {
                relation: 'roleMappings',
                scope: { include: ['role'] },
              },
            },
          },
        ],
      }),
    });

    await this.$store.dispatch('partners/getPartner', {
      filters,
      partnerId: this.auth.user.partnerId,
    });
    await this.fetchUpcomingInvoice();
    this.loading = false;

    await this.fetchPaymentMethod();
    this.fetchInvoices();
    this.fetchCommissionStatements();
  },

  methods: {
    formatCentsAsDollars,

    async fetchCommissionStatements() {
      const statements = await this.$store.dispatch('partners/getCommissionStatements');
      this.commissionStatements = statements.map((statement) => ({
        ...statement,
        date: format(new Date(statement.created * 1000), 'MM/dd/yyyy'),
        total: this.formatCentsAsDollars(statement.total),
      }));
    },

    async fetchInvoices() {
      const { invoices, refunds } = await this.$store.dispatch('partners/getInvoices');
      this.invoices = invoices.map((invoice) => ({
        ...invoice,
        date: format(new Date(invoice.created * 1000), 'MM/dd/yyyy'),
        total: this.formatCentsAsDollars(invoice.total),
        status: invoice.status,
      }));
      this.refunds = refunds.map((refund) => ({
        ...refund,
        date: format(new Date(refund.created * 1000), 'MM/dd/yyyy'),
        total: this.formatCentsAsDollars(refund.amount),
      }));
    },

    async fetchPaymentMethod() {
      const partnerId = this.$store.getters['partners/partner'].id;
      this.paymentMethod = await this.$store.dispatch('partners/getPaymentMethod', { partnerId });
    },

    async fetchUpcomingInvoice() {
      this.upcomingInvoice = await this.$store.dispatch('partners/getUpcomingInvoice');
    },

    setPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
      // fetch invoices in case any past due charges
      // were retried
      this.fetchInvoices();
    },
  },
};
</script>
