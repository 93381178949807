<template>
  <div class="grid lg:grid-cols-2 gap-4">
    <template v-if="loading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <div>
        <vx-card
          title-color="#fff"
          card-background="primary"
          content-color="#fff"
          class="p-6"
        >
          <template slot="no-body">
            <div class="flex flex-col md:h-48 items-center justify-center">
              <feather-icon
                icon="SmileIcon"
                class="inline-block mr-2"
                svg-classes="w-16 h-16"
              />
              <div class="mt-3 text-center">
                <p class="text-2xl">
                  Welcome <span class="font-semibold">{{ `${user.firstName} ${user.lastName}` }}</span>!
                </p>
                <p>
                  We hope we’re making your day a little bit brighter.
                </p>
              </div>
            </div>
          </template>
        </vx-card>

        <div class="grid grid-cols-2 grid-flow-row gap-4 pt-4">
          <statistics-card-line
            color="rjBlue"
            hide-chart
            icon="PackageIcon"
            icon-right
            :statistic="plan.name"
            statistic-title="Partner Plan"
            :class="{'cursor-pointer': !isElitePartner}"
            @click="shouldUpgrade"
          />

          <statistics-card-line
            color="rjGreen"
            hide-chart
            icon="DollarSignIcon"
            icon-right
            :statistic="`${commissionRate}%`"
            statistic-title="Commission Rate"
          />

          <statistics-card-line
            color="rjOrange"
            hide-chart
            icon="UsersIcon"
            icon-right
            :statistic="companies.length"
            statistic-title="Companies"
          />

          <statistics-card-line
            color="rjRed"
            hide-chart
            icon="PercentIcon"
            icon-right
            :statistic="eliteBonusEligible ? '10%' : '0%'"
            statistic-title="Elite Partner Bonus"
            :class="{'cursor-pointer': !isElitePartner}"
            @click="shouldUpgrade"
          />
        </div>
      </div>

      <div>
        <vx-card title="Companies">
          <template slot="no-body">
            <div class="pt-6 mx-6">
              <vs-table
                search
                pagination
                class="table-fixed"
                :data="companies"
                :max-items="itemsPerPage"
                :no-data-text="'No Companies'"
                @selected="jumpToCompany"
              >
                <template slot="header">
                  <div class="flex flex-wrap-reverse items-center flex-grow justify-between">
                    <!-- ADD NEW BUTTON -->
                    <div class="mt-auto">
                      <vs-button
                        color="primary"
                        class="w-full"
                        @click="addCompany"
                      >
                        Add Company
                      </vs-button>
                    </div>
                  </div>
                </template>

                <template slot="thead">
                  <vs-th
                    sort-key="company"
                    class="w-7/12"
                  >
                    COMPANY
                  </vs-th>
                  <vs-th
                    sort-key="locations"
                    class="w-1/12"
                  >
                    <span class="w-full text-center">LOCATIONS</span>
                  </vs-th>
                  <vs-th
                    sort-key="plan"
                    class="w-2/12"
                  >
                    <span class="w-full text-center">PLAN</span>
                  </vs-th>
                  <vs-th class="w-2/12">
                    <span class="w-full text-center">ACTION</span>
                  </vs-th>
                </template>

                <template slot-scope="{ data }">
                  <tbody>
                    <vs-tr
                      v-for="(company, index) in data"
                      :key="index"
                      :data="company"
                    >
                      <vs-td
                        class="w-7/12 whitespace-no-wrap overflow-hidden"
                        style="max-width: 0.063rem;"
                      >
                        <p class="font-medium truncate">
                          {{ company.name }}
                        </p>
                      </vs-td>

                      <vs-td
                        class="w-1/12 whitespace-no-wrap overflow-hidden"
                        style="max-width: 0.063rem;"
                      >
                        <p class="font-medium truncate text-center">
                          {{ company.locations.length }}
                        </p>
                      </vs-td>

                      <vs-td class="w-2/12 whitespace-no-wrap">
                        <p class="font-medium text-center">
                          {{ company.plan.name }}
                        </p>
                      </vs-td>

                      <vs-td class="w-2/12 whitespace-no-wrap">
                        <div class="flex flex-no-wrap">
                          <vs-button
                            icon-pack="feather"
                            icon="icon-settings"
                            type="flat"
                            class="inline-block"
                            title="Company Settings"
                            :color="themeColors.rjPurple"
                            @click.stop="jumpToCompany(company)"
                          />
                          <vs-button
                            icon-pack="feather"
                            icon="icon-external-link"
                            type="flat"
                            class="inline-block"
                            title="Open Company Dashboard"
                            :color="themeColors.rjPurple"
                            @click.stop="openCompanyDashboard(company)"
                          />
                          <!-- Only if plan is not High Jump -->
                          <vs-button
                            v-if="company.plan.code !== HIGH_JUMP_CODE"
                            icon-pack="feather"
                            icon="icon-arrow-up"
                            type="flat"
                            class="inline-block"
                            title="Upgrade Plan"
                            :color="themeColors.rjPurple"
                            @click.stop="openPlanUpgradeDialog(company)"
                          />
                        </div>
                      </vs-td>
                    </vs-tr>
                  </tbody>
                </template>
              </vs-table>
            </div>
          </template>
        </vx-card>
      </div>
    </template>
  </div>
</template>

<script>
import { bus, httpBuildQuery } from '@/utils';
import Authorizable from '@/mixins/Authorizable';
import { colors as themeColors } from '@/../themeConfig';
import * as codes from '@/rj_consts';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';

export default {
  name: 'RjHome',

  components: { StatisticsCardLine },

  mixins: [Authorizable],

  data() {
    return {
      itemsPerPage: 5,
      themeColors,
      loading: true,
      upcomingInvoice: null,
      HIGH_JUMP_CODE: codes.HIGH_JUMP_CODE,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/currentUser'];
    },

    partner() {
      return this.$store.getters['partners/partner'] || {};
    },

    plan() {
      return this.$store.getters['partners/plan'] || {
        name: '',
      };
    },

    companies() {
      const companies = this.$store.getters['companies/companies'] || [];
      const inHouseCompanyId = this.$store.getters['partners/inHouseCompany']?.id;
      return companies.filter((c) => c.id !== inHouseCompanyId);
    },

    isElitePartner() {
      return this.plan.name === codes.ELITE_PARTNER_PLAN;
    },

    eliteBonusEligible() {
      return this.$store.getters['partners/eliteBonusEligible'];
    },

    numHighJumps() {
      const highJumpLineItem = this.upcomingInvoice?.lineItems.find((item) => item.code === codes.HIGH_JUMP_CODE);
      if (!highJumpLineItem) {
        return 0;
      }
      return highJumpLineItem.quantity;
    },

    commissionRate() {
      // Alias it to improve code readability.
      const h = this.numHighJumps;
      if (!h || h <= 25) {
        return 20;
      }
      if (h >= 26 && h <= 99) {
        return 30;
      }
      if (h >= 100) {
        return 40;
      }
      return 0;
    },
    location() {
      const locations = this.$store.getters['locations/locations'];
      if (locations.length) {
        return locations[0];
      }
      return null;
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      const user = this.$store.getters['auth/currentUser'];
      await this.$store.dispatch('partners/loadData', user.partnerId);
      this.loading = false;
      this.upcomingInvoice = await this.$store.dispatch('partners/getUpcomingInvoice');
    },

    /**
     * Emits a global event to the PartnerServiceProvied start the add company form wizard.
     *
     * @return {void}
     */
    addCompany() {
      bus.$emit('show-modal', { modal: 'add-company' });
    },

    /**
     * Show upgrade to Elite Partner modal.
     *
     * @return {void}
     */
    shouldUpgrade() {
      if (!this.isElitePartner) {
        bus.$emit('show-modal', { modal: 'become-elite' });
      }
    },

    /**
     * Opens the company dashboard for ELite Partners or invites Certified Partners to upgrade.
     *
     * @param  {Object} company
     * @return {mixed}
     */
    openCompanyDashboard(company) {
      if (!this.isElitePartner) {
        return bus.$emit('elite-upgrade-cta');
      }

      localStorage.setItem('actAsCompanyId', company.id);

      const route = this.$router.resolve({
        name: 'company-home',
      });
      return window.open(route.href, '_blank');
    },

    /**
     * Emits a global event to the PartnerServiceProvider start the company upgrade proccess.
     *
     * @param  {Object} company
     * @return {void}
     */
    openPlanUpgradeDialog(company) {
      const filters = httpBuildQuery({
        filter: JSON.stringify({
          include: [
            'plan',
            {
              relation: 'companyPlans',
              scope: {
                where: {
                  active: true,
                },
                include: ['plan'],
              },
            },
            {
              relation: 'locations',
              scope: { where: { pending: false } },
            },
          ],
        }),
      });
      this.$store.dispatch('companies/getCompany', { companyId: company.id, filters });
      bus.$emit('upgrade-company', company);
    },

    /**
     * Navigates to the given company page.
     *
     * @param  {Object} company.id
     * @return {void}
     */
    jumpToCompany({ id }) {
      this.$router.push({
        name: 'partner-company',
        params: { company: id, slug: 'general' },
      });
    },
  },
};
</script>
