<template>
  <section class="rj-invoice-modal">
    <header class="p-4 flex items-center justify-end no-print">
      <vs-button
        type="border"
        @click="download"
      >
        Download
      </vs-button>
      <a
        ref="downloadLink"
        class="hidden"
      />

      <vs-button
        class="ml-3"
        @click="print"
      >
        Print
      </vs-button>
    </header>

    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
    >
      <main
        v-if="invoice"
        id="invoice-page"
        slot="pdf-content"
        ref="invoice"
        class="max-w-lg mx-auto"
      >
        <div id="invoice-container">
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-1/2 mt-base">
              <img
                src="/rj-logo.png"
                alt="RevenueJump Logo"
                class="w-48"
              >
            </div>

            <div class="vx-col w-1/2 text-right">
              <h1>Invoice</h1>
              <div class="invoice__invoice-detail mt-6">
                <p>#{{ invoice.number }} - {{ invoiceDate }}</p>
              </div>
            </div>

            <div class="vx-col w-full mt-4 my-4">
              <div class="flex justify-between">
                <span />
                <p class="font-bold">
                  BILL TO
                </p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.addressLine1 }}</p>
                <p>{{ invoice.partner.addressName || invoice.partner.name }}</p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.addressLine2 }} {{ companyDetails.zipcode }}</p>
                <p>{{ invoice.partner.addressStreet }}</p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.mailId }}</p>
                <p>{{ invoice.partner.addressCity }} {{ invoice.partner.addressRegion }} {{ invoice.partner.addressZip }}</p>
              </div>
            </div>

            <!-- INVOICE CONTENT -->
            <div class="mt-3 w-full">
              <table class="w-full">
                <thead class="border-b-2 border-gray-400">
                  <tr>
                    <th class="py-1 px-3 m-0 w-full">
                      PLAN
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      <span v-if="!isManualInvoice">AMOUNT</span>
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      <span v-if="!isManualInvoice">QTY</span>
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      SUBTOTAL
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="lineItem in invoice.lineItems">
                    <tr :key="lineItem.id">
                      <td class="py-1 px-3">
                        <p>{{ lineItem.description }}</p>
                      </td>
                      <td class="py-1 px-3 text-right">
                        <p v-if="!isManualInvoice">
                          {{ lineItem.code === "discount" ? "" : formatCentsAsDollars(lineItem.unitAmount) }}
                        </p>
                      </td>
                      <td class="py-1 px-3 text-right">
                        <p v-if="!isManualInvoice">
                          {{ lineItem.quantity }}
                        </p>
                      </td>
                      <td class="py-1 px-3 text-right">
                        <p>{{ formatCentsAsDollars(lineItem.amount) }}</p>
                      </td>
                    </tr>
                    <template v-if="lineItem.details">
                      <tr :key="`dtl-${lineItem.id}`">
                        <td colspan="4">
                          <div
                            class="rounded-lg pl-6 py-1 pr-2"
                            :style="{ 'background-color': Color(themeColors.rjPurple).lightness(93).hex() }"
                          >
                            <span
                              v-if="lineItem.details.companies"
                              class="font-semibold"
                            >Companies:</span>
                            <span
                              v-for="(company, index) in lineItem.details.companies"
                              :key="`cmp-${company.id}`"
                            > {{ company.name }}<span v-if="company.count > 1"> ({{ company.count }})</span><span v-if="index !== lineItem.details.companies.length - 1">,</span></span>
                            <p v-if="lineItem.showSavings">
                              <span class="font-semibold">Monthly Savings:</span> {{ formatCentsAsDollars(lineItem.quantity * retailValueForCode(lineItem.code)) }}
                            </p>
                            <span v-if="lineItem.details.coupon">{{ lineItem.details.coupon.percent_off ? `${lineItem.details.coupon.percent_off}% ${lineItem.details.coupon.name} - ` : "" }} {{ lineItem.details.coupon.description }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>

              <div class="flex justify-between border-t-2 border-gray-400 py-2 mt-2">
                <div class="w-7/12 space-y-2 mr-4">
                  <div class="leading-tight">
                    <span class="font-semibold">Currency:</span> USD
                  </div>

                  <div class="leading-tight">
                    <p class="font-semibold">
                      Terms of Service:
                    </p>
                    <a
                      href="https://www.revenuejump.com/terms-of-service"
                      target="_blank"
                    >
                      https://www.revenuejump.com/terms-of-service
                    </a>
                  </div>

                  <div
                    v-if="failedCharges.length"
                    class="leading-tight"
                  >
                    <span class="font-semibold">
                      Failed Payment Attempts:
                    </span>
                    <span
                      v-for="date of failedChargeDates"
                      :key="date"
                    >{{ date }}</span>
                  </div>
                </div>

                <div class="w-5/12 space-y-2 text-right">
                  <div class="leading-tight text-xl">
                    <p
                      class="uppercase text-sm font-bold"
                      :style="{ color: Color(themeColors.rjPurple).lightness(70).hex() }"
                    >
                      Total
                    </p>
                    <span class="text-2xl font-bold">{{ invoiceTotal }}</span>
                  </div>

                  <div class="leading-tight">
                    <span class="font-semibold">Status:</span>
                    <span v-if="invoice.status === 'Pending'">
                      PAST DUE
                    </span>
                    <span v-else-if="invoice.status === 'Paid'">
                      Paid *{{ invoice.paidWith.last4 }} {{ paidAt }}
                    </span>
                    <span v-else>
                      {{ invoice.status }}
                    </span>
                  </div>

                  <div class="leading-tight">
                    <span class="font-semibold">Balance:</span>
                    {{ amountRemaining }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </vue-html2pdf>
  </section>
</template>

<script>
import Color from 'color';
import { format } from 'date-fns';
import VueHtml2pdf from 'vue-html2pdf';
import { formatCentsAsDollars } from '@/utils';
import Partner from '@/http/middleware/Partner';
import Auth from '@/http/middleware/Authenticate';
import { colors as themeColors } from '@/../themeConfig';
import {
  HIGH_JUMP_CODE, HIGH_JUMP_RETAIL_VALUE, LOW_JUMP_CODE, LOW_JUMP_RETAIL_VALUE,
} from '@/rj_consts';

export default {
  name: 'RjInvoice',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueHtml2pdf,
  },
  middleware: [Auth, Partner],

  data() {
    return {
      Color,
      themeColors,
      active: true,
      invoice: null,
      companyDetails: {
        name: 'RevenueJump',
        addressLine1: '6700 N Linder Rd #156-143',
        addressLine2: 'Meridian, ID',
        zipcode: '83646',
        mailId: 'support@revenuejump.com',
        mobile: '',
      },
    };
  },

  computed: {
    amountRemaining() {
      return formatCentsAsDollars(this.invoice.amountRemaining);
    },
    filename() {
      if (!this.invoice) {
        return 'RevenueJump-Invoice';
      }
      return `RevenueJump-Invoice-${this.invoice.number}`;
    },
    invoiceDate() {
      return format(new Date(this.invoice.created * 1000), 'MM/dd/yyyy');
    },
    invoiceTotal() {
      return formatCentsAsDollars(this.invoice.total);
    },
    isManualInvoice() {
      return this.invoice.billingReason === 'manual';
    },
    paidAt() {
      return format(new Date(this.invoice.paidAt * 1000), 'MM/dd/yy');
    },
    failedCharges() {
      return this.invoice.charges.filter((charge) => !charge.paid);
    },
    failedChargeDates() {
      return this.failedCharges.map((charge) => format(new Date(charge.created * 1000), 'MM/dd/yy')).join(', ');
    },
  },

  async created() {
  //   document.body.classList.toggle('overflow-hidden');
  //   document.addEventListener('keydown', this.onEscape);
    const invoiceId = this.$route.params.invoice;
    const partnerId = this.$route.params.partner;
    this.invoice = await this.$store.dispatch('partners/getInvoice', { partnerId, invoiceId });
  },

  // beforeDestroy() {
  //   document.body.classList.toggle('overflow-hidden');
  //   document.removeEventListener('keydown', this.onEscape);
  // },

  methods: {
    /**
     * Listener for the 'Esc' key to close the modal
     *
     * @param  {Event} event
     * @return {void}
     */
    onEscape(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    },

    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      this.active = false;
      this.$emit('close');
    },

    print() {
      window.print();
    },

    async download() {
      this.$refs.html2Pdf.generatePdf();
    },

    retailValueForCode(code) {
      switch (code) {
      case LOW_JUMP_CODE:
        return LOW_JUMP_RETAIL_VALUE;
      case HIGH_JUMP_CODE:
        return HIGH_JUMP_RETAIL_VALUE;
      default:
        return 0;
      }
    },
  },
};
</script>

<style>
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.vue-html2pdf .layout-container {
  position: static !important;
  background-color: #fff !important;
  z-index: 0;
}
</style>
