<template>
  <vs-prompt
    title="Payment Status"
    :active="active"
    accept-text="Save"
    :style="{ zIndex: 60000 }"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <form>
      <!-- Role -->
      <div class="mt-3">
        <label class="vs-input--label">Status</label>
        <rj-select
          v-model="form.status"
          v-validate="'required'"
          name="Status"
          :options="statuses"
          :clearable="false"
          placeholder="Select a status..."
        />
        <span class="text-sm text-red-500">
          {{ errors.first('Status') }}
        </span>
      </div>

      <div
        v-if="form.status === 'Paid'"
        class="mt-3"
      >
        <label class="vs-input--label">Date</label>
        <div class="w-full">
          <datepicker
            v-model="form.paidAt"
            format="MMM dd, yyyy"
            input-class="p-2"
            placeholder="Select a date..."
          />
        </div>
      </div>

      <div
        v-if="form.status === 'Paid'"
        class="mt-3"
      >
        <label class="vs-input--label">Method</label>
        <rj-select
          v-model="form.paymentMethod"
          v-validate="'required'"
          name="Method"
          :options="paymentMethods"
          :clearable="false"
          placeholder="Select a payment method..."
        />
        <span class="text-sm text-red-500">
          {{ errors.first('Method') }}
        </span>
      </div>
    </form>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import _cloneDeep from 'lodash/cloneDeep';
import Datepicker from 'vuejs-datepicker';
import { isPristine } from '@/utils';

export default {
  name: 'RjCommissionStatementStatusModal',

  components: {
    Datepicker,
  },

  props: {
    active: {
      type: Boolean,
      requried: true,
    },

    commissionStatement: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        status: '',
        paymentMethod: '',
        paidAt: '',
      },
      statuses: [
        'Pending',
        'Paid',
      ],
      paymentMethods: [
        'ACH',
        'Check',
        'Discount/Credit',
        'PayPal',
      ],
      old: {},
      pristine: true,
    };
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    this.buildFormObject();
  },

  methods: {
    /**
     * Decides which action the modal should take.
     *
     * @return {void}
     */
    async submit() {
      const result = this.$validator.validateAll();
      if (!result) {
        return;
      }

      this.update();
    },

    /**
     * Updates the current User.
     *
     * @return {void}
     */
    async update() {
      const payload = {
        ...this.form,
        id: this.commissionStatement.id,
      };
      await this.$store.dispatch('partners/updateCommissionStatement', payload);
      this.$emit('updated');
      this.close();
    },

    /**
     * Sets the form values with the given commission statement data
     *
     * @param  {Object} user
     * @return {void}
     */
    buildFormObject() {
      const { paidAt, status, paymentMethod } = this.commissionStatement;
      this.form.status = status;
      if (paidAt) {
        this.form.paidAt = new Date(paidAt * 1000);
      } else {
        this.form.paidAt = new Date();
      }
      this.form.paymentMethod = paymentMethod;
      this.old = _cloneDeep(this.form);
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      if (current.status === 'Pending') {
        this.form.paidAt = null;
        this.form.paymentMethod = null;
      }
      if (current.status === 'Paid' && !current.paidAt) {
        this.form.paidAt = new Date();
      }
      this.pristine = isPristine(current, this.old);
    }, 50),

    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
