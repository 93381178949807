<template>
  <rj-modal ref="modal">
    <!-- Step 0 - Location Address -->
    <template v-if="step === 0">
      <rj-wizard-step
        :index="0"
        @close="close"
      >
        <rj-client-address
          :client-address-data="clientAddressData"
          @submit="clientAddress"
        />
      </rj-wizard-step>
    </template>

    <!-- Step 1 - Review Sites -->
    <template v-if="step === 1">
      <rj-wizard-step
        :index="1"
        @back="previous"
        @close="close"
      >
        <rj-review-sites
          :client-address-data="clientAddressData"
          :client-details-data="clientDetailsData"
          :review-sites-data="reviewSitesData"
          @submit="reviewSites"
          @review-sites-updated="setReviewSites"
        />
      </rj-wizard-step>
    </template>

    <!-- Step 2 - Connect Facebook -->
    <template v-if="step === 2">
      <rj-wizard-step
        :index="2"
        @back="previous"
        @close="close"
      >
        <rj-facebook-connector
          :facebook-access-token="facebookAccessToken"
          @skip="next"
          @facebook-auth-updated="setFacebookAccessToken"
        />
      </rj-wizard-step>
    </template>

    <!-- Step 3 - Client Settings -->
    <template v-if="step === 3">
      <rj-wizard-step
        :index="3"
        :back-button="!hideNav"
        :close-button="!hideNav"
        @back="previous"
        @close="close"
      >
        <rj-client-settings
          :plan="plan"
          :submitting="submitting"
          :client-address-data="clientAddressData"
          :client-settings-data="clientSettingsData"
          @submit="clientSettings"
          @toggle-nav="toggleNavigation"
        />
      </rj-wizard-step>
    </template>

    <!-- Step 4 - Confirmation Screen -->
    <template v-if="step === 4">
      <rj-wizard-step
        :index="4"
        :back-button="false"
        :close-button="!submitting"
        @close="completeWizard"
      >
        <rj-confirmation-screen
          :submitting="submitting"
          @finish="completeWizard"
          @add-another="addAnotherLocation"
        />
      </rj-wizard-step>
    </template>
  </rj-modal>
</template>

<script>
import { httpBuildQuery } from '@/utils';
import RjModal from '@/layouts/components/RjModal.vue';
import RjWizardStep from '@/layouts/components/rj-form-wizard/RjWizardStep.vue';
import RjReviewSites from '@/views/partner-dashboard/companies/form-wizard/RjReviewSites.vue';
import RjClientAddress from '@/views/partner-dashboard/companies/form-wizard/RjClientAddress.vue';
import RjClientSettings from '@/views/partner-dashboard/companies/form-wizard/RjClientSettings.vue';
import RjFacebookConnector from '@/views/partner-dashboard/companies/form-wizard/RjFacebookConnector.vue';
import RjConfirmationScreen from '@/views/partner-dashboard/locations/form-wizard/RjConfirmationScreen.vue';

export default {
  name: 'RjAddLocationWizard',

  components: {
    RjModal,
    RjWizardStep,
    RjClientAddress,
    RjReviewSites,
    RjClientSettings,
    RjFacebookConnector,
    RjConfirmationScreen,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    plan: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      step: 0,
      country: null,
      submitting: false,
      hideNav: false,
      clientDetailsData: {
        companyName: this.company.name,
      },
      clientAddressData: {
        addressCountry: this.company.addressCountry,
        addressStreet: '',
        addressCity: '',
        addressRegion: '',
        addressZip: '',
        phone: '',
      },
      clientSettingsData: {
        fromEmail: '',
        fromName: '',
        filterNegativeFeedback: 1,
      },
      facebookAccessToken: '',
      reviewSitesData: [],
    };
  },

  mounted() {
    this.$store.dispatch('locations/deletePending', this.company.id);
    const filters = httpBuildQuery({
      filter: JSON.stringify({
        where: { companyId: this.company.id },
      }),
    });
    this.$store.dispatch('funnels/fetchFunnels', filters);
  },

  methods: {
    /**
     * RjClientAddress submit handler.
     *
     * @param  {void} payload
     * @return {void}
     */
    clientAddress(payload) {
      this.clientAddressData = payload;
      this.next();
    },

    /**
     * RjReviewSites submit handler.
     *
     * @param  {Object} payload
     * @return {void}
     */
    reviewSites(reviewSites) {
      this.reviewSitesData = reviewSites;
      this.next();
    },

    /**
     * RjFacebookConnector submit handler.
     *
     * @return {void}
     */
    setFacebookAccessToken(accessToken) {
      this.facebookAccessToken = accessToken;
      this.next();
    },

    /**
     * RjClientSettings submit handler.
     *
     * @param  {Object} payload
     * @return {void}
     */
    async clientSettings(payload) {
      this.clientSettingsData = payload;

      this.submitting = true;
      await this.$store.dispatch('companies/addHighJumpLocation', {
        ...this.clientAddressData,
        ...this.clientSettingsData,
        facebookAccessToken: this.facebookAccessToken,
        locationReviewSites: this.reviewSitesData.filter((r) => r.profileUrl),
      });

      this.submitting = false;
      this.next();
    },

    async completeWizard() {
      try {
        this.submitting = true;
        await this.$store.dispatch('locations/charge', this.company.id);
        await this.fetchLocations();

        this.close();
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      } finally {
        this.submitting = false;
      }
    },

    /**
     * RjConfirmationScreen submit handler
     *
     * @param  {Object} payload
     * @return {void}
     */
    addAnotherLocation() {
      this.reset();
      this.step = 0;
    },

    toggleNavigation() {
      this.hideNav = !this.hideNav;
    },

    setReviewSites(reviewSites) {
      this.reviewSitesData = reviewSites;
    },

    next() {
      this.step++;
    },

    previous() {
      this.step -= (this.step === 0) ? 0 : 1;
    },

    reset() {
      this.clientAddressData = {
        addressCountry: this.company.addressCountry,
        addressStreet: '',
        addressCity: '',
        addressRegion: '',
        addressZip: '',
        phone: '',
      };

      this.reviewSitesData = [];

      this.clientSettingsData = {
        fromEmail: '',
        fromName: '',
        filterNegativeFeedback: 1,
      };

      this.facebookAccessToken = '';
    },

    async fetchLocations() {
      const filters = httpBuildQuery({
        includeSurveyStats: 1,
        filter: JSON.stringify({
          where: { active: true, companyId: this.company.id },
        }),
      });

      return this.$store.dispatch('locations/fetchLocations', filters);
    },

    close() {
      if (this.step === 4) {
        return this.$emit('close');
      }

      // The Accept and Cancel actions are inverted because
      // it is not possible to change the buttons position in the UI.
      return this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Are You Sure?',
        text: 'You will lose all unsaved changes.',
        cancelText: 'Accept',
        buttonCancel: 'filled',
        cancel: () => this.$emit('close'),
        acceptText: 'Cancel',
        buttonAccept: 'border',
      });
    },
  },
};
</script>
