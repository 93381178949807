<template>
  <section>
    <template v-if="loading">
      Loading Users...
    </template>

    <template v-else>
      <div
        id="table__company-users"
        class="rv-default-data-list data-list-container"
      >
        <rj-users-list
          :users="users"
          @selected="toggleUserModal"
        >
          <template #actions>
            <vs-button
              class="w-full"
              @click="addUser()"
            >
              Add User
            </vs-button>
          </template>

          <template #table-row="{ user }">
            <!-- NAME -->
            <vs-td class="whitespace-no-wrap">
              <p class="font-medium truncate">
                {{ user.firstName }} {{ user.lastName }}
              </p>
            </vs-td>

            <!-- ROLE -->
            <vs-td class="whitespace-no-wrap">
              <p class="font-medium truncate w-full text-center">
                {{ user.role.description }}
              </p>
            </vs-td>

            <!-- SURVEYS SENT -->
            <vs-td class="whitespace-no-wrap">
              <p class="font-medium w-full text-center">
                {{ user.surveysCount }}
              </p>
            </vs-td>

            <!-- VISIT RATE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="user.visitRate"
                  color="danger"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ user.visitRate }}%</span>
              </div>
            </vs-td>

            <!-- COMPLETION RATE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="user.completionRate"
                  color="success"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ user.completionRate }}%</span>
              </div>
            </vs-td>

            <!-- SATISFACTION SCORE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="user.satisfactionScore"
                  color="warning"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ user.satisfactionScore }}%</span>
              </div>
            </vs-td>

            <!-- ACTION -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-button
                  icon-pack="feather"
                  icon="icon-settings"
                  type="flat"
                  class="inline-block"
                  title="User Settings"
                />
                <vs-button
                  icon-pack="feather"
                  icon="icon-slash"
                  type="flat"
                  class="inline-block"
                  title="Delete User"
                  @click.stop="removeConfirmation(user)"
                />
              </div>
            </vs-td>
          </template>
        </rj-users-list>
      </div>
    </template>

    <rj-user-modal
      v-if="modal"
      context="company"
      :user="selected"
      :company="company"
      :active.sync="modal"
      @create-user="create"
      @update-user="update"
      @should-upgrade="triggerUpgradeIntent"
    />

    <rj-users-pack-modal
      v-if="usersPackModal"
      :active.sync="usersPackModal"
      :counts="counts"
    />
  </section>
</template>

<script>
import useUsersTab from '@/views/common/users/useUsersTab';
import RjUsersList from '@/views/common/users/RjUsersList.vue';
import RjUserModal from '@/views/common/users/modals/RjUserModal.vue';
import RjUsersPackModal from '@/views/common/users/modals/RjUsersPackModal.vue';

export default {
  name: 'RjUsersTab',

  components: {
    RjUserModal,
    RjUsersList,
    RjUsersPackModal,
  },

  mixins: [useUsersTab],

  props: {
    company: {
      type: Object,
      required: true,
    },
  },
};
</script>
