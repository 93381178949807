<template>
  <section>
    <template v-if="loading">
      <p>Loading Funnels...</p>
    </template>

    <template v-else>
      <div
        id="table__funnels"
        class="rv-default-data-list data-list-container"
      >
        <rj-funnels-list
          :funnels="funnels"
          @selected="jumpToFunnel"
        >
          <template #actions>
            <vs-button
              class="w-full"
              @click="add"
            >
              Add Funnel
            </vs-button>
          </template>

          <template #table-row="{ funnel }">
            <!-- NAME -->
            <vs-td>
              <p class="font-medium truncate">
                {{ funnel.name }}
              </p>
            </vs-td>

            <!-- TYPE -->
            <vs-td>
              <p class="font-medium truncate w-full text-center">
                {{ funnel.type }}
              </p>
            </vs-td>

            <!-- SURVEYS SENT -->
            <vs-td>
              <p class="font-medium truncate w-full text-center">
                {{ funnel.thirtyDayCount }}
              </p>
            </vs-td>

            <!-- VISIT RATE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="funnel.visitRate"
                  color="danger"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ funnel.visitRate }}%</span>
              </div>
            </vs-td>

            <!-- COMPLETION RATE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="funnel.completionRate"
                  color="success"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ funnel.completionRate }}%</span>
              </div>
            </vs-td>

            <!-- SATISFACTION SCORE -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-progress
                  class="shadow w-16"
                  :percent="funnel.satisfactionScore"
                  color="warning"
                  height="8"
                />
                <span class="ml-3 text-sm text-gray-600">{{ funnel.satisfactionScore }}%</span>
              </div>
            </vs-td>

            <!-- ACTION -->
            <vs-td class="whitespace-no-wrap">
              <div class="w-full flex justify-center">
                <vs-button
                  icon-pack="feather"
                  icon="icon-settings"
                  type="flat"
                  class="inline-block"
                  title="Funnel Settings"
                  @click.stop="jumpToFunnel(funnel)"
                />
                <vs-button
                  icon-pack="feather"
                  icon="icon-copy"
                  type="flat"
                  class="inline-block"
                  title="Copy Funnel"
                  @click.stop="copy(funnel)"
                />
                <vs-button
                  icon-pack="feather"
                  icon="icon-slash"
                  type="flat"
                  class="inline-block"
                  title="Delete Funnel"
                  @click.stop="removeConfirmation(funnel)"
                />
              </div>
            </vs-td>
          </template>
        </rj-funnels-list>
      </div>
    </template>
  </section>
</template>

<script>
import { bus } from '@/utils';
import useFunnels from '@/views/common/funnels/useFunnels';
import RjFunnelsList from '@/views/common/funnels/RjFunnelsList.vue';

export default {
  name: 'RjFunnelsTab',

  components: {
    RjFunnelsList,
  },

  mixins: [useFunnels],

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    funnels() {
      return this.$store.getters['funnels/funnels'];
    },
  },

  async mounted() {
    await this.fetch(this.company.id);
    this.loading = false;
  },

  methods: {
    /**
     * Navigate to the funnel creation form
     *
     * @return {void}
     */
    add() {
      if (this.hasReachedLimit && this.isLowJump) {
        return bus.$emit('upgrade-company-intent', this.company);
      }

      return this.$router.push({
        name: 'partner-funnel-settings',
        params: { funnel: 'create' },
      });
    },

    /**
     * Navigates to the given funnel.
     *
     * @param  {Object} funnel
     * @return {void}
     */
    jumpToFunnel(funnel) {
      this.$router.push({
        name: 'partner-funnel-settings',
        params: { funnel: funnel.id },
      });
    },
  },
};
</script>
