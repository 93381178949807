<template>
  <section>
    <template v-if="loading">
      <p>Loading Partner...</p>
    </template>

    <template v-else>
      <div class="flex flex-wrap -mx-4">
        <div class="w-full sm:w-1/2 px-4 mb-4">
          <rj-partner-details-form />
        </div>

        <div class="w-full sm:w-1/2 px-4 mb-4">
          <rj-partner-users-table />
        </div>
      </div>
      <div>
        <rj-partner-email-settings />
      </div>
    </template>
  </section>
</template>

<script>
import { httpBuildQuery } from '@/utils';
import RjPartnerUsersTable from '@/views/partner-dashboard/settings/partials/RjPartnerUsersTable.vue';
import RjPartnerDetailsForm from '@/views/partner-dashboard/settings/partials/RjPartnerDetailsForm.vue';
import RjPartnerEmailSettings from '@/views/partner-dashboard/settings/partials/RjPartnerEmailSettings.vue';

export default {
  name: 'RjSettings',

  components: {
    RjPartnerUsersTable,
    RjPartnerDetailsForm,
    RjPartnerEmailSettings,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      const filters = httpBuildQuery({
        filter: JSON.stringify({
          include: [
            'mainContact',
            {
              relation: 'users',
              scope: {
                include: {
                  relation: 'roleMappings',
                  scope: { include: ['role'] },
                },
              },
            },
          ],
        }),
      });

      await this.$store.dispatch('partners/getPartner', {
        filters,
        partnerId: this.auth.user.partnerId,
      });
      this.loading = false;
    },
  },
};
</script>
