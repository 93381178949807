<template>
  <section class="w-full">
    <vx-card title="Email Notifications">
      <div v-if="loading">
        <p>Loading...</p>
      </div>
      <div
        v-else
      >
        <div class="w-full pb-4">
          <p class="pb-2">
            Email notifications will be sent to your clients from the following email address.
          </p>
          <form class="flex justify-start">
            <!-- From Email -->
            <div class="pr-8 w-1/3">
              <vs-input
                v-model="form.fromEmail"
                v-validate="'required|email'"
                label="From Email"
                name="From Email"
                class="w-full"
                @input="validateFromForm"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('From Email') }}
              </span>
            </div>
            <!-- From Name -->
            <div class="w-1/3">
              <vs-input
                v-model="form.fromName"
                v-validate="'required'"
                label="From Name"
                name="From Name"
                class="w-full"
                @input="validateFromForm"
              />
              <span class="text-sm text-red-500">
                {{ errors.first('From Name') }}
              </span>
            </div>
            <div class="mt-6">
              <vs-button
                v-if="fromEmailValid && fromNameValid"
                class="ml-3"
                @click.prevent="submitFromForm"
              >
                <span v-if="submitting">Saving...</span>
                <span v-else>Save</span>
              </vs-button>
            </div>
          </form>
        </div>
        <div class="pb-2">
          <p class="pb-2">
            To improve the deliverability of your email notifications, authenticate your domain with our email provider. Enter your domain below. Do not include the <em>https</em>, <em>www</em>, or similar characters at the beginning of your domain unless you use it in your email address.
          </p>
          <form>
            <vs-input
              v-model="form.domain"
              v-validate="{ regex: /^[A-Za-z\-]+\.[A-Za-z\-]+$/ }"
              label="Your Domain"
              name="domain"
              class="w-full"
              placeholder="example.com"
              :disabled="verified"
              @input="validateDomain"
              @keydown.enter.prevent="submitDomain"
            />
            <span class="text-sm text-red-500">
              {{ errors.first('domain') }}
            </span>
            <div class="flex flex-wrap items-center justify-end mt-6">
              <vs-button
                v-if="domainNameValid && !verified"
                class="ml-3"
                @click.prevent="submitDomain"
              >
                <span v-if="submitting">Submitting...</span>
                <span v-else>Next</span>
              </vs-button>
            </div>
          </form>
        </div>

        <div
          v-if="sendgridDomain && !submitting"
        >
          <p class="pb-2">
            Copy and paste the following records into the DNS settings where your domain is hosted (i.e. Cloudflare, GoDaddy, etc.). Be careful if your DNS host automatically adds your domain to the end of the record. This will duplicate your domain in the record and it will not work. If this happens, delete the duplicate domain.
          </p>
          <vs-table
            ref="table"
            :data="domainItems"
            :hover-flat="true"
          >
            <template #thead>
              <vs-th>
                STATUS
              </vs-th>
              <vs-th>
                TYPE
              </vs-th>
              <vs-th>
                NAME
              </vs-th>
              <vs-th>
                VALUE
              </vs-th>
            </template>
            <template #default="{ data }">
              <vs-tr
                v-for="(item, index) in data"
                :key="index"
              >
                <vs-td
                  v-if="item.errorMessage"
                  colspan="4"
                >
                  <p class="bg-orange-200 rounded p-2">
                    {{ item.errorMessage }}
                  </p>
                </vs-td>
                <vs-td v-if="item.host">
                  <p
                    v-if="item.valid"
                    class="text-green-500"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      svg-classes="h-4 w-4"
                      class="pr-2"
                    />
                    Verified
                  </p>
                  <p
                    v-else-if="item.reason"
                    class="text-red-500"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      svg-classes="h-4 w-4"
                      class="pr-2"
                    />
                    Failed
                  </p>
                  <p
                    v-else
                  >
                    <feather-icon
                      icon="MinusCircleIcon"
                      svg-classes="h-4 w-4"
                      class="pr-2"
                    />
                    Pending
                  </p>
                </vs-td>
                <vs-td v-if="item.host">
                  <p class="font-medium uppercase">
                    {{ item.type }}
                  </p>
                </vs-td>
                <vs-td v-if="item.host">
                  <div class="flex justify-between items-center">
                    <p class="font-medium">
                      {{ item.host }}
                    </p>
                    <textarea
                      ref="items"
                      v-model="item.host"
                      style="display: none;"
                    />
                    <p>
                      <vs-button
                        type="border"
                        class="h-10 py-0 px-4"
                        @click="copy(item.host, index)"
                      >
                        Copy
                      </vs-button>
                    </p>
                  </div>
                </vs-td>
                <vs-td v-if="item.host">
                  <div class="flex justify-between items-center">
                    <p class="font-medium">
                      {{ item.data }}
                    </p>
                    <textarea
                      ref="items"
                      v-model="item.data"
                      style="display: none;"
                    />
                    <p>
                      <vs-button
                        type="border"
                        class="h-10 py-0 px-4"
                        @click="copy(item.data, index)"
                      >
                        Copy
                      </vs-button>
                    </p>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div
            v-if="verified"
            ref="verificationActions"
            class="flex justify-start py-4"
          >
            <vs-button
              type="border"
              @click="deleteConfirmation"
            >
              <span v-if="deleting">Deleting...</span>
              <span v-else>Delete</span>
            </vs-button>
          </div>
          <div
            v-else
            ref="verificationActions"
            class="flex justify-between items-center py-4"
          >
            <div>
              <vs-button
                type="border"
                @click="deleteConfirmation"
              >
                <span v-if="deleting">Deleting...</span>
                <span v-else>Delete</span>
              </vs-button>
            </div>
            <div class="flex">
              <vs-checkbox
                :value="form.verifiedChecked"
                class="m-0 pr-2"
                @input="(value) => form.verifiedChecked = value"
              >
                I've added these records.
              </vs-checkbox>
              <vs-button
                :disabled="!form.verifiedChecked"
                @click="verify"
              >
                <span v-if="verifying">Verifying...</span>
                <span v-else>Verify</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </section>
</template>
<script>
export default {
  name: 'RjEmailDomainAuth',
  data() {
    return {
      form: {
        fromEmail: '',
        fromName: '',
        domain: '',
        verifiedChecked: false,
      },
      fromEmailValid: false,
      fromNameValid: false,
      domainNameValid: false,
      sendgridDomain: null,
      loading: true,
      submitting: false,
      verifying: false,
      deleting: false,
    };
  },
  computed: {
    fromFormValid() {
      return this.form.fromName && this.form.fromEmail;
    },
    domainItems() {
      if (!this.sendgridDomain) {
        return [];
      }
      const {
        mailCNAME,
        dkim1,
        dkim2,
      } = this.sendgridDomain;

      const items = [mailCNAME];

      // if (mailCNAME.reason) {
      //   items.push({
      //     errorMessage: mailCNAME.reason,
      //   });
      // }

      items.push(dkim1);

      // if (dkim1.reason) {
      //   items.push({
      //     errorMessage: dkim1.reason,
      //   });
      // }

      items.push(dkim2);

      // if (dkim2.reason) {
      //   items.push({
      //     errorMessage: dkim2.reason,
      //   });
      // }

      return items;
    },
    verified() {
      if (!this.domainItems.length) {
        return false;
      }
      return this.domainItems.every((item) => item.valid);
    },
    partner() {
      return this.$store.getters['partners/partner'];
    },
  },
  async mounted() {
    try {
      this.form.fromEmail = this.partner.fromEmail || '';
      this.form.fromName = this.partner.fromName || '';

      const { domains: [domain] } = await this.$store.dispatch('partners/getEmailDomains', this.partner.id);
      if (domain) {
        this.sendgridDomain = domain;
        this.form.domain = domain.domain;
      }
    } catch (error) {
      //
    } finally {
      this.loading = false;
    }
  },
  methods: {
    validateFromForm() {
      this.$validator.validate('From Email').then((valid) => {
        this.fromEmailValid = valid;
      });
      this.$validator.validate('From Name').then((valid) => {
        this.fromNameValid = valid;
      });
    },
    validateDomain() {
      if (!this.form.domain) {
        this.domainNameValid = false;
        return;
      }
      this.$validator.validate('domain').then((valid) => {
        this.domainNameValid = valid;
      });
    },
    async submitFromForm() {
      this.submitting = true;
      try {
        const payload = {
          partnerId: this.partner.id,
          fromEmail: this.form.fromEmail,
          fromName: this.form.fromName,
          name: this.partner.name,
        };
        await this.$store.dispatch('partners/updatePartnerDetails', payload);
        this.fromEmailValid = false;
        this.fromNameValid = false;
        this.$vs.notify({ title: 'Success', text: 'Email settings saved.', color: 'success' });
      } finally {
        this.submitting = false;
      }
    },
    async submitDomain() {
      this.submitting = true;
      try {
        const payload = {
          domain: this.form.domain,
          partnerId: this.partner.id,
        };
        const {
          id,
          mailCNAME,
          dkim1,
          dkim2,
        } = await this.$store.dispatch('partners/authenticateEmailDomain', payload);
        this.sendgridDomain = {
          id,
          mailCNAME,
          dkim1,
          dkim2,
        };
        this.$nextTick(() => {
          this.$refs.verificationActions.scrollIntoView({ behvaior: 'smooth' });
        });
      } finally {
        this.submitting = false;
      }
    },
    async verify() {
      this.verifying = true;
      try {
        const payload = {
          domainId: this.sendgridDomain.id,
          partnerId: this.partner.id,
        };
        const {
          valid,
          mailCNAME,
          dkim1,
          dkim2,
        } = await this.$store.dispatch('partners/verifyEmailDomain', payload);
        if (valid) {
          this.sendgridDomain.mailCNAME.valid = true;
          this.sendgridDomain.dkim1.valid = true;
          this.sendgridDomain.dkim2.valid = true;
        } else {
          this.sendgridDomain.mailCNAME = {
            ...this.sendgridDomain.mailCNAME,
            ...mailCNAME,
          };
          this.sendgridDomain.dkim1 = {
            ...this.sendgridDomain.dkim1,
            ...dkim1,
          };
          this.sendgridDomain.dkim2 = {
            ...this.sendgridDomain.dkim2,
            ...dkim2,
          };
        }
      } finally {
        this.verifying = false;
      }
    },
    deleteConfirmation() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'You are about to delete your email domain settings.',
        acceptText: 'Delete',
        accept: () => this.deleteDomain(),
      });
    },
    async deleteDomain() {
      this.deleting = true;
      try {
        const payload = {
          domainId: this.sendgridDomain.id,
          partnerId: this.partner.id,
        };
        await this.$store.dispatch('partners/deleteEmailDomain', payload);
      } finally {
        this.sendgridDomain = null;
        this.form = {
          domain: '',
          verifiedChecked: false,
        };
        this.domainNameValid = false;
        this.deleting = false;
      }
    },
    async copy(text, refsIndex) {
      await this.clipboard(text, this.$refs.items[refsIndex]);
      this.$vs.notify({
        title: 'Copied',
        text: 'Item was copied to your clipboard.',
        color: 'success',
      });
    },

    clipboard(text, element) {
      if (navigator.clipboard) {
        return navigator.clipboard.writeText(text);
      }

      element.$el.select();
      document.execCommand('Copy');
      element.$el.setSelectionRange(0, 0);
      return document.execCommand('Copy');
    },
  },
};
</script>
